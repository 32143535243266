<template>
	<div class="content-wrap">
		<!--档案统计报表-->
		<Spin fix v-if="loading"></Spin>
		<CtmsSearchBox @onSearch="searchHandle" @onReset="reset">
			<CtmsSearchBoxItem :span="1" label="项目方案">
				<Input
					v-model="searchParams.projectNameFuzzy"
					clearable
					placeholder="项目方案名称"
				></Input>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="研究负责人">
				<Select
					v-model="searchParams.piName"
					:transfer="true"
					clearable
					filterable
					placeholder="请选择研究负责人"
				>
					<Option :value="list.realName" v-for="list in piList" :key="list.id"
						>{{ list.realName }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
			<CtmsSearchBoxItem :span="1" label="科室">
				<Select
					v-model="searchParams.department"
					:transfer="true"
					filterable
					clearable
					placeholder="请选择科室"
				>
					<Option
						:value="depart.name"
						v-for="depart in departList"
						:key="depart.id"
						>{{ depart.name }}
					</Option>
				</Select>
			</CtmsSearchBoxItem>
		</CtmsSearchBox>
		<CtmsAction>
			<Button v-if="data.length" @click="exportData">导出档案统计报表</Button>
		</CtmsAction>
		<CtmsDataGrid
			:height="dataGridHeight"
			:columns="getTableCols"
			:data="data"
			:page="page"
			:showIndex="false"
			ref="table"
		></CtmsDataGrid>
	</div>
</template>

<script>
import api from "@/api/report/report"
import fundsApi from "@/api/project/funds"
import { mapState } from "vuex"

const { apiGetArchives } = api
const { apiGetDeparts, getResearchUers } = fundsApi
export default {
	name: "archives",
	data() {
		return {
			loading: false,
			searchParams: {
				projectNameFuzzy: "",
				piName: "",
				department: ""
			},
			oldSearchParams: {
				projectNameFuzzy: "",
				piName: "",
				department: ""
			},
			headers: [],
			data: [],
			columns: [],
			departList: [],
			piList: [],
			page: {
				current: 1,
				pageSize: 10,
				on: {
					onChange: this.onChange,
					onPageSizeChange: this.onPageSizeChange
				},
				total: 0
			}
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 140
		}),
		getTableCols() {
			return this.headers.map(item => Object.assign({ minWidth: 150 }, item))
		}
	},
	mounted() {
		this.getPublicData()
		this.initData()
	},
	methods: {
		getPublicData() {
			// 获取科室
			this.$asyncDo(async () => {
				const res = await apiGetDeparts()
				if (res) {
					this.departList = res.data || []
				}
			})
			// 获取研究负责人 主要研究者
			this.$asyncDo(async () => {
				const res = await getResearchUers({
					roleId: 10
				})
				if (res) {
					this.piList = res.data || []
				}
			})
		},
		initData() {
			this.$asyncDo(async () => {
				this.loading = true
				const res = await apiGetArchives({
					...this.oldSearchParams,
					pageNum: this.page.current,
					pageSize: this.page.pageSize
				})
				if (res && res.list && res.list.length) {
					this.headers = []
					const obj = res.list[0]
					for (const key in obj) {
						this.headers.push({
							title: obj[key],
							key
						})
					}
					this.data = res.list.slice(1)
				}
				this.page.total = res ? res.total : 0
				this.loading = false
			})
		},
		exportData() {
			/*
			 * projectNameFuzzy 项目方案
			 * piName 研究负责人
			 * department 专业科室
			 * */
			const projectNameFuzzy = this.searchParams.projectNameFuzzy || ""
			const piName = this.searchParams.piName || ""
			const department = this.searchParams.department || ""
			const url = `${this.$baseUrl}/report/export-file-manage?projectNameFuzzy=${projectNameFuzzy}&piName=${piName}&department=${department}&token=${this.$store.state.user.token}`
			window.open(url)
		},
		// 重置
		reset() {
			this.searchParams = {
				projectNameFuzzy: "",
				piName: "",
				department: ""
			}
			this.page.current = 1
			this.searchHandle()
		},
		searchHandle() {
			this.page.current = 1
			this.oldSearchParams = Object.assign({}, this.searchParams)
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面改变
		onChange(current) {
			this.page.current = current
			this.$nextTick(() => {
				this.initData()
			})
		},
		// 页面显示数量改变
		onPageSizeChange(pageSize) {
			this.page.pageSize = pageSize
			this.$nextTick(() => {
				this.initData()
			})
		}
	}
}
</script>
